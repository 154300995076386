var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "kycVerifyModal",
    attrs: {
      "centered": "",
      "size": _vm.showTips ? 'md' : 'xl',
      "hide-footer": true,
      "dialog-class": "kycVerifyModal-dialog",
      "header-class": "kycVerifyModal-header",
      "data-testid": "eKYC-header",
      "body-class": "kycVerifyModal-body"
    },
    on: {
      "hide": _vm.kycVerifyModalHidden
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function () {
        return [_c('h2', {
          staticClass: "package-kycVerifyModal-title"
        }, [_vm._v("\n      " + _vm._s(!_vm.showTips ? _vm.$t('scenes.ekyc.tips.navigation-title') : _vm.$t('scenes.ekyc.tips.title')) + "\n    ")])];
      },
      proxy: true
    }, {
      key: "modal-header-close",
      fn: function () {
        return [_c('i', {
          staticClass: "airalo-icon-close-round-filled tw-block tw-text-5.5 tw-mt-2.5 tw-mr-2.5"
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.kycVerifyModalData.isShow,
      callback: function ($$v) {
        _vm.$set(_vm.kycVerifyModalData, "isShow", $$v);
      },
      expression: "kycVerifyModalData.isShow"
    }
  }, [_vm._v(" "), _vm._v(" "), _c('div', {
    staticClass: "c--shared_kyc-verify-modal"
  }, [_vm.showTips ? _c('div', {
    staticClass: "kycVerifyModal-content",
    attrs: {
      "data-testid": "eKYC-content"
    }
  }, [_c('div', {
    staticClass: "tips-description tp-6 mb-20",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('scenes.ekyc.tips.subtitle'))
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "tips-description tp-6 mb-40",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('scenes.ekyc.tips.content-web'))
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "kycVerifyModal-content-privacy"
  }, [_vm.privacyInfo.kyc_type === 'agreement_checkbox' ? _c('b-form-checkbox', {
    staticClass: "new-checkbox",
    attrs: {
      "value": true,
      "unchecked-value": false
    },
    model: {
      value: _vm.privacyInfo.checkbox,
      callback: function ($$v) {
        _vm.$set(_vm.privacyInfo, "checkbox", $$v);
      },
      expression: "privacyInfo.checkbox"
    }
  }, [_c('i18n', {
    attrs: {
      "path": "scenes.ekyc.tips.agreement-checkbox-policy",
      "tag": "p"
    }
  }, [_c('a', {
    attrs: {
      "place": "providerPrivacyPolicy",
      "href": _vm.privacyInfo.providerPolicyUrl,
      "target": "_blank"
    }
  }, [_vm._v("\n              " + _vm._s(_vm.privacyInfo.providerPolicy) + "\n            ")]), _vm._v(" "), _c('span', {
    attrs: {
      "place": "operatorName"
    }
  }, [_vm._v(_vm._s(_vm.privacyInfo.operatorName))]), _vm._v(" "), _c('a', {
    attrs: {
      "place": "operatorPrivacyPolicy",
      "href": _vm.privacyInfo.privacy_policy_url,
      "target": "_blank"
    }
  }, [_vm._v("\n              " + _vm._s(_vm.$t('global.privacy')) + "\n            ")])])], 1) : _vm.privacyInfo.kyc_type === 'agreement' ? _c('i18n', {
    attrs: {
      "path": "scenes.ekyc.tips.agreement-policy",
      "tag": "p"
    }
  }, [_c('a', {
    attrs: {
      "place": "providerPrivacyPolicy",
      "href": _vm.privacyInfo.providerPolicyUrl,
      "target": "_blank"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.privacyInfo.providerPolicy) + "\n          ")]), _vm._v(" "), _c('span', {
    attrs: {
      "place": "operatorName"
    }
  }, [_vm._v(_vm._s(_vm.privacyInfo.operatorName))]), _vm._v(" "), _c('a', {
    attrs: {
      "place": "operatorPrivacyPolicy",
      "href": _vm.privacyInfo.privacy_policy_url,
      "target": "_blank"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$t('global.privacy')) + "\n          ")])]) : _c('i18n', {
    attrs: {
      "path": "scenes.ekyc.tips.no-agreement-policy",
      "tag": "p"
    }
  }, [_c('a', {
    attrs: {
      "place": "providerPrivacyPolicy",
      "href": _vm.privacyInfo.providerPolicyUrl,
      "target": "_blank"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.privacyInfo.providerPolicy) + "\n          ")])])], 1), _vm._v(" "), _c('button', {
    staticClass: "kycVerifyModal-continueBtn call-to-action dark d-block btn-primary-hv",
    attrs: {
      "data-testid": "eKYC-continue-button"
    },
    on: {
      "click": function ($event) {
        return _vm.kycSubmit();
      }
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('global.continue')) + "\n      ")])]) : _c('b-embed', {
    attrs: {
      "type": "iframe",
      "src": _vm.modalSrc,
      "allow": "camera;fullscreen;accelerometer;gyroscope;magnetometer",
      "allowfullscreen": ""
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }