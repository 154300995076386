<template>
  <div class="c--input_toggle toggle-container" :class="{ active: value }" @click="() => toggleAction()">
    <div class="toggle-node main-shadow" data-testid="toggle"></div>
  </div>
</template>

<script>
  export default {
    name: 'Toggle',
    props: ['value'],
    methods: {
      toggleAction() {
        this.$emit('change', !this.value)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .toggle-container {
    position: relative;
    height: 1.6rem;
    width: 3rem;
    display: inline-block;
    background: rgb(231, 229, 229);
    border-radius: 2rem;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    cursor: pointer;
  }

  .toggle-container.active {
    background: #7ec027;
  }

  .toggle-node {
    position: absolute;
    left: 0.2rem;
    top: 0.15rem;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    display: block;
    width: 1.32rem;
    height: 1.32rem;
    background: white;
    border-radius: 2rem;

    [dir='rtl'] & {
      right: 0.2rem;
    }
  }

  .toggle-container.active .toggle-node {
    left: 1.5rem;

    [dir='rtl'] & {
      right: 1.5rem;
    }
  }
</style>
