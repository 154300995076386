var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c--homepage_kyc-rejected-warning alert-warning",
    class: {
      'alert-warning-no-verify': !_vm.isVerify,
      'alert-warning-verify': _vm.isVerify
    }
  }, [_c('div', {
    staticClass: "error d-flex align-items-center",
    attrs: {
      "role": "alert"
    }
  }, [_c('span', {
    staticClass: "message"
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-start"
  }, [_c('span', {
    staticClass: "alert-warning-content"
  }, [_vm._v("\n          " + _vm._s(_vm.translate) + "\n        ")]), _vm._v(" "), _vm.isGoDetail && _vm.kycIdentity ? _c('button', {
    staticClass: "btn kyc-rejected-warning-btn btn-block",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.gotoEkycDetail
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$t('components.ekyc.warning.button-title')) + "\n        ")]) : _vm._e()])])]), _vm._v(" "), _c('ekyc-detail-modal')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }