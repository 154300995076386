<template>
  <div
    class="c--sim_card-package package-base tw-overflow-hidden"
    :style="`background: linear-gradient(${
      $dir() === 'rtl' ? 270 : 90
    }deg,${sim_gradient_start} 0,${sim_gradient_end})`"
  >
    <div class="package-base-header" :class="simTheme">
      <p class="tx-center">
        {{ sim_package.title }}
      </p>
    </div>
    <ul class="package-base-list">
      <!-- Data -->
      <li>
        <sim-item-row
          :theme="simTheme"
          icon="airalo-icon-arrows-up-and-down"
          :key-text="$t('global.data')"
          :value="pkgMxn_packageHasUnlimitedData ? $t('global.data-unlimited') : sim_package.data"
        ></sim-item-row>
      </li>
      <!-- Voice -->
      <li v-if="topupPackageHasDVTVoice">
        <sim-item-row
          :theme="simTheme"
          :icon-is-svg="true"
          icon="ic_calls"
          :key-text="$t('global.calls')"
          :value="
            pkgMxn_packageHasUnlimitedVoice
              ? $t('global.calls-unlimited')
              : $t('global.minutes', {
                  amount: sim_package.voice,
                })
          "
        ></sim-item-row>
      </li>
      <!-- Text -->
      <li v-if="topupPackageHasDVTText">
        <sim-item-row
          :theme="simTheme"
          :icon-is-svg="true"
          icon="ic_sms"
          :key-text="$t('global.texts')"
          :value="
            pkgMxn_packageHasUnlimitedText
              ? $t('global.texts-unlimited')
              : $t('global.sms', {
                  amount: sim_package.text,
                })
          "
        ></sim-item-row>
      </li>
      <!-- Validity -->
      <li>
        <sim-item-row
          :theme="simTheme"
          icon="airalo-icon-calendar"
          :key-text="$t('global.validity')"
          :value="`${sim_package.day} ${dayText(sim_package.day)}`"
        ></sim-item-row>
      </li>
      <!-- Price -->
      <li>
        <sim-item-row
          :theme="simTheme"
          :icon-is-svg="true"
          icon="ic_price_v2"
          :key-text="$t('global.price')"
          :value="pkgMxn_packageNonPromotedPrice.formatted"
          :is-last="true"
          :is-price="true"
          :price-has-promotion="pkgMxn_packageHasPromotion"
          :is-promoted-price="false"
        ></sim-item-row>
      </li>
      <li v-if="pkgMxn_packageHasPromotion">
        <sim-item-row
          :theme="simTheme"
          :icon-is-svg="true"
          icon="ic_price_v2"
          :value="pkgMxn_packagePromotedPrice.formatted"
          :is-last="true"
          :is-price="true"
          :is-promoted-price="true"
          :discount-percentage="pkgMxn_packagePromotionDiscount"
          :sale-price-end-date-iso="pkgMxn_packagePromotionEndDateIso"
          :tooltip-target-id="tooltipTargetId"
        ></sim-item-row>
      </li>
    </ul>
  </div>
</template>

<script>
  import pkgMxn from '~/mixins/package.js'

  export default {
    name: 'CardPackage',

    components: {
      SimItemRow: () => import('~/components/sim/item-row.vue'),
    },

    mixins: [pkgMxn(true, 'sim_package')],

    props: {
      main_package: {
        type: Object,
        required: true,
      },
      sim_package: {
        type: Object,
        required: true,
      },
      sim_gradient_start: {
        type: String,
        required: true,
      },
      sim_gradient_end: {
        type: String,
        required: true,
      },
      sim_isdark: {
        type: Boolean,
        required: true,
      },
    },

    computed: {
      simTheme() {
        return this.sim_isdark ? 'dark' : 'light'
      },

      //  These computed properties are NOT substituted by the ones in pkgMxn because
      //  topupPackageIsDVT checks for main_package instead of sim_package (used in mixin)
      topupPackageIsDVT() {
        return this.main_package?.operator?.plan_type === 'data-voice-text'
      },
      topupPackageHasDVTVoice() {
        const isDVT = this.topupPackageIsDVT
        const hasVoice = this.pkgMxn_packageHasVoice
        const voiceIsUnlimited = this.pkgMxn_packageHasUnlimitedVoice

        return isDVT && (hasVoice || voiceIsUnlimited)
      },
      topupPackageHasDVTText() {
        const isDVT = this.topupPackageIsDVT
        const hasText = this.pkgMxn_packageHasText
        const textIsUnlimited = this.pkgMxn_packageHasUnlimitedText

        return isDVT && (hasText || textIsUnlimited)
      },

      tooltipTargetId() {
        return `card-package-sale-price-${this.sim_package.id}`
      },
    },

    methods: {
      dayText(day) {
        return parseInt(day) < 2 ? this.$t('global.day') : this.$t('global.days')
      },
    },
  }
</script>

<style lang="scss" scoped>
  .package-base {
    display: inline-block;
    width: 100%;
    border-radius: 7px;
    position: relative;

    &-header {
      padding: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid transparent;
      min-height: 85px;

      p {
        margin: 0;
        font-size: 1.1875rem;
        font-weight: 600;
        letter-spacing: -0.2px;
        line-height: 1.157894;
        text-align: center;
      }

      &.light {
        border-bottom-color: rgba(#eeeeee, 0.1);

        p {
          color: #ffffff;
        }
      }
      &.dark {
        border-bottom-color: rgba(#343434, 0.1);

        color: #4a4a4a;
      }
    }
    .card-row-base {
      &.header {
        padding-top: 24px;
        padding-bottom: 24px;
        font-weight: 600;
        font-size: 1.1875rem;
        letter-spacing: -0.2px;
        line-height: 1.421;
        align-items: center;
      }
      .key-text {
        .icon-package-price-wrapper,
        .icon-homepage-country-data-wrapper,
        .icon-package-validity-wrapper {
          display: inline-flex;
        }
      }
    }
  }
</style>
