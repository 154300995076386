var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c--sim_card-package package-base tw-overflow-hidden",
    style: `background: linear-gradient(${_vm.$dir() === 'rtl' ? 270 : 90}deg,${_vm.sim_gradient_start} 0,${_vm.sim_gradient_end})`
  }, [_c('div', {
    staticClass: "package-base-header",
    class: _vm.simTheme
  }, [_c('p', {
    staticClass: "tx-center"
  }, [_vm._v("\n      " + _vm._s(_vm.sim_package.title) + "\n    ")])]), _vm._v(" "), _c('ul', {
    staticClass: "package-base-list"
  }, [_c('li', [_c('sim-item-row', {
    attrs: {
      "theme": _vm.simTheme,
      "icon": "airalo-icon-arrows-up-and-down",
      "key-text": _vm.$t('global.data'),
      "value": _vm.pkgMxn_packageHasUnlimitedData ? _vm.$t('global.data-unlimited') : _vm.sim_package.data
    }
  })], 1), _vm._v(" "), _vm.topupPackageHasDVTVoice ? _c('li', [_c('sim-item-row', {
    attrs: {
      "theme": _vm.simTheme,
      "icon-is-svg": true,
      "icon": "ic_calls",
      "key-text": _vm.$t('global.calls'),
      "value": _vm.pkgMxn_packageHasUnlimitedVoice ? _vm.$t('global.calls-unlimited') : _vm.$t('global.minutes', {
        amount: _vm.sim_package.voice
      })
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.topupPackageHasDVTText ? _c('li', [_c('sim-item-row', {
    attrs: {
      "theme": _vm.simTheme,
      "icon-is-svg": true,
      "icon": "ic_sms",
      "key-text": _vm.$t('global.texts'),
      "value": _vm.pkgMxn_packageHasUnlimitedText ? _vm.$t('global.texts-unlimited') : _vm.$t('global.sms', {
        amount: _vm.sim_package.text
      })
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('li', [_c('sim-item-row', {
    attrs: {
      "theme": _vm.simTheme,
      "icon": "airalo-icon-calendar",
      "key-text": _vm.$t('global.validity'),
      "value": `${_vm.sim_package.day} ${_vm.dayText(_vm.sim_package.day)}`
    }
  })], 1), _vm._v(" "), _c('li', [_c('sim-item-row', {
    attrs: {
      "theme": _vm.simTheme,
      "icon-is-svg": true,
      "icon": "ic_price_v2",
      "key-text": _vm.$t('global.price'),
      "value": _vm.pkgMxn_packageNonPromotedPrice.formatted,
      "is-last": true,
      "is-price": true,
      "price-has-promotion": _vm.pkgMxn_packageHasPromotion,
      "is-promoted-price": false
    }
  })], 1), _vm._v(" "), _vm.pkgMxn_packageHasPromotion ? _c('li', [_c('sim-item-row', {
    attrs: {
      "theme": _vm.simTheme,
      "icon-is-svg": true,
      "icon": "ic_price_v2",
      "value": _vm.pkgMxn_packagePromotedPrice.formatted,
      "is-last": true,
      "is-price": true,
      "is-promoted-price": true,
      "discount-percentage": _vm.pkgMxn_packagePromotionDiscount,
      "sale-price-end-date-iso": _vm.pkgMxn_packagePromotionEndDateIso,
      "tooltip-target-id": _vm.tooltipTargetId
    }
  })], 1) : _vm._e()])]);

}
var staticRenderFns = []

export { render, staticRenderFns }