var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c--sim_card-package-carousel swiper-card-package-carousel",
    attrs: {
      "data-testid": "top-up-carousel"
    }
  }, [_c('div', {
    directives: [{
      name: "swiper",
      rawName: "v-swiper:mySwiper",
      value: _vm.swiperOption,
      expression: "swiperOption",
      arg: "mySwiper"
    }],
    ref: "availablePackagesSwiper"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, _vm._l(_vm.sim_packages, function (pkg, index) {
    return _c('div', {
      key: `card-package-carousel-${index}`,
      staticClass: "swiper-slide"
    }, [[_c('client-only', [!_vm.cardPackageShow ? [_c('div', {
      staticClass: "preloader-item"
    }, [_c('div', {
      staticClass: "preloader lds-roller"
    }, [_c('div'), _vm._v(" "), _c('div'), _vm._v(" "), _c('div'), _vm._v(" "), _c('div'), _vm._v(" "), _c('div'), _vm._v(" "), _c('div'), _vm._v(" "), _c('div'), _vm._v(" "), _c('div')])])] : _vm._e()], 2), _vm._v(" "), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.cardPackageShow,
        expression: "cardPackageShow"
      }],
      staticClass: "card-package-wrapper",
      attrs: {
        "data-testid": "top-up-package"
      }
    }, [_c('CardPackage', {
      attrs: {
        "main_package": _vm.main_package,
        "sim_package": pkg,
        "sim_isdark": _vm.sim_isdark,
        "sim_gradient_start": _vm.sim_gradient_start,
        "sim_gradient_end": _vm.sim_gradient_end
      }
    })], 1)]], 2);
  }), 0), _vm._v(" "), _c('div', {
    staticClass: "swiper-pagination",
    attrs: {
      "slot": "pagination"
    },
    slot: "pagination"
  }), _vm._v(" "), _c('div', {
    staticClass: "swiper-button swiper-button-prev",
    attrs: {
      "slot": "button-prev",
      "data-testid": "previous-arrow"
    },
    slot: "button-prev"
  }, [_vm._m(0)]), _vm._v(" "), _c('div', {
    staticClass: "swiper-button swiper-button-next",
    attrs: {
      "slot": "button-next",
      "data-testid": "next-arrow"
    },
    slot: "button-next"
  }, [_vm._m(1)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', [_c('span', {
    staticClass: "airalo-icon-arrow-right tw-text-7"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', [_c('span', {
    staticClass: "airalo-icon-arrow-right tw-text-7"
  })]);

}]

export { render, staticRenderFns }