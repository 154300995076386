<template>
  <div
    class="c--homepage_kyc-rejected-warning alert-warning"
    :class="{
      'alert-warning-no-verify': !isVerify,
      'alert-warning-verify': isVerify,
    }"
  >
    <div class="error d-flex align-items-center" role="alert">
      <span class="message">
        <div class="d-flex flex-column align-items-start">
          <span class="alert-warning-content">
            {{ translate }}
          </span>
          <button
            v-if="isGoDetail && kycIdentity"
            type="button"
            class="btn kyc-rejected-warning-btn btn-block"
            @click="gotoEkycDetail"
          >
            {{ $t('components.ekyc.warning.button-title') }}
          </button>
        </div>
      </span>
    </div>
    <ekyc-detail-modal />
  </div>
</template>

<script>
  export default {
    name: 'package-buy-warning',
    props: ['translate', 'isGoDetail', 'kycIdentity'],
    components: {
      EkycDetailModal: () => import('~/components/shared/ekyc-detail-modal.vue'),
    },
    computed: {
      isVerify() {
        return this.kycIdentity && this.kycIdentity.status === 'approved'
      },
    },
    methods: {
      gotoEkycDetail() {
        this.$root.$emit('ekyc-detail-modal', this.kycIdentity)
        this.$utils.sendEvent('gotoEkycDetailButtonTapped')
      },
    },
  }
</script>

<style lang="scss" scoped>
  .alert-warning {
    position: relative;
    padding: 20px;
    color: #fff;
    border-radius: 7px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
    overflow: hidden;

    .message {
      width: 100%;
    }

    &-verify {
      background-color: #7ec027;

      &:after {
        background: url('/assets/images/svg/Check-Banner.svg') no-repeat center;
      }
    }

    &-no-verify {
      background: linear-gradient(90deg, #ff512f 0%, #f09819 100%);

      [dir='rtl'] & {
        background: linear-gradient(270deg, #ff512f 0%, #f09819 100%);
      }
      &:after {
        background: url('/assets/images/svg/Warning-Banner.svg') no-repeat center;
      }
    }

    &:after {
      content: '';
      position: absolute;
      width: 86px;
      height: 75px;
      bottom: -6px;
      right: -19px;
      opacity: 0.8;
      background-size: 86px 75px;
      [dir='rtl'] & {
        right: unset;
        left: -25px;
      }
    }

    &-content {
      color: #ffffff;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 18px;
    }

    .kyc-rejected-warning-btn {
      border: 1px solid #ffffff;
      border-radius: 4px;
      margin-top: 10px;
      padding: 8px 9px;
      color: #ffffff;
      font-size: 10px;
      font-weight: bold;
      letter-spacing: 1.5px;
      line-height: 10px;
      text-align: center;

      &:focus {
        box-shadow: none;
      }
    }
  }
</style>
