<template>
  <div class="c--sim_card-package-carousel swiper-card-package-carousel" data-testid="top-up-carousel">
    <div v-swiper:mySwiper="swiperOption" ref="availablePackagesSwiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(pkg, index) in sim_packages" :key="`card-package-carousel-${index}`">
          <template>
            <client-only>
              <template v-if="!cardPackageShow">
                <div class="preloader-item">
                  <div class="preloader lds-roller">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </template>
            </client-only>
            <div data-testid="top-up-package" class="card-package-wrapper" v-show="cardPackageShow">
              <CardPackage
                :main_package="main_package"
                :sim_package="pkg"
                :sim_isdark="sim_isdark"
                :sim_gradient_start="sim_gradient_start"
                :sim_gradient_end="sim_gradient_end"
              />
            </div>
          </template>
        </div>
      </div>

      <div class="swiper-pagination" slot="pagination"></div>
      <div class="swiper-button swiper-button-prev" slot="button-prev" data-testid="previous-arrow">
        <button>
          <span class="airalo-icon-arrow-right tw-text-7" />
        </button>
      </div>
      <div class="swiper-button swiper-button-next" slot="button-next" data-testid="next-arrow">
        <button>
          <span class="airalo-icon-arrow-right tw-text-7" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import CardPackage from '~/components/sim/card-package.vue'
  import { directive } from 'vue-awesome-swiper'
  import 'swiper/css/swiper.css'

  export default {
    name: 'CardPackageCarousel',

    components: {
      CardPackage,
    },

    directives: {
      swiper: directive,
    },

    props: {
      main_package: {
        type: Object,
        required: true,
      },
      sim_packages: {
        type: Array,
        required: false,
        default: [],
      },
      sim_gradient_start: {
        type: String,
        required: false,
        default: '',
      },
      sim_gradient_end: {
        type: String,
        required: false,
        default: '',
      },
      sim_isdark: {
        type: Boolean,
        required: false,
        default: false,
      },
      isTest: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    data() {
      return {
        isCollapsedActiveElement: true,
        swiperOption: {
          slidesPerView: 1,
          spaceBetween: 21,
          breakpoints: {
            // when window width is >= 576px
            576: {
              slidesPerView: 2,
              spaceBetween: 31,
            },
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          on: {
            beforeInit: () => {
              this.cardPackageShow = false
            },
            init: () => {
              this.cardPackageShow = true
            },
          },
        },
        cardPackageShow: false,
      }
    },
  }
</script>

<style scoped>
  .swiper-wrapper {
    align-items: flex-start;
  }
  .swiper-button.swiper-button-prev {
    left: -6px !important;
  }

  .swiper-button.swiper-button-next {
    right: -6px !important;
  }
</style>
