var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c--input_toggle toggle-container",
    class: {
      active: _vm.value
    },
    on: {
      "click": function () {
        return _vm.toggleAction();
      }
    }
  }, [_c('div', {
    staticClass: "toggle-node main-shadow",
    attrs: {
      "data-testid": "toggle"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }