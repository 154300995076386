<template>
  <b-modal
    v-model="kycVerifyModalData.isShow"
    ref="kycVerifyModal"
    centered
    :size="showTips ? 'md' : 'xl'"
    :hide-footer="true"
    @hide="kycVerifyModalHidden"
    dialog-class="kycVerifyModal-dialog"
    header-class="kycVerifyModal-header"
    data-testid="eKYC-header"
    body-class="kycVerifyModal-body"
  >
    <template #modal-title>
      <h2 class="package-kycVerifyModal-title">
        {{ !showTips ? $t('scenes.ekyc.tips.navigation-title') : $t('scenes.ekyc.tips.title') }}
      </h2>
    </template>
    <template #modal-header-close>
      <i class="airalo-icon-close-round-filled tw-block tw-text-5.5 tw-mt-2.5 tw-mr-2.5" />
    </template>
    <div class="c--shared_kyc-verify-modal">
      <div v-if="showTips" class="kycVerifyModal-content" data-testid="eKYC-content">
        <div v-html="$t('scenes.ekyc.tips.subtitle')" class="tips-description tp-6 mb-20" />
        <div v-html="$t('scenes.ekyc.tips.content-web')" class="tips-description tp-6 mb-40" />
        <div class="kycVerifyModal-content-privacy">
          <b-form-checkbox
            v-if="privacyInfo.kyc_type === 'agreement_checkbox'"
            v-model="privacyInfo.checkbox"
            class="new-checkbox"
            :value="true"
            :unchecked-value="false"
          >
            <i18n path="scenes.ekyc.tips.agreement-checkbox-policy" tag="p">
              <a place="providerPrivacyPolicy" :href="privacyInfo.providerPolicyUrl" target="_blank">
                {{ privacyInfo.providerPolicy }}
              </a>
              <span place="operatorName">{{ privacyInfo.operatorName }}</span>
              <a place="operatorPrivacyPolicy" :href="privacyInfo.privacy_policy_url" target="_blank">
                {{ $t('global.privacy') }}
              </a>
            </i18n>
          </b-form-checkbox>
          <i18n v-else-if="privacyInfo.kyc_type === 'agreement'" path="scenes.ekyc.tips.agreement-policy" tag="p">
            <a place="providerPrivacyPolicy" :href="privacyInfo.providerPolicyUrl" target="_blank">
              {{ privacyInfo.providerPolicy }}
            </a>
            <span place="operatorName">{{ privacyInfo.operatorName }}</span>
            <a place="operatorPrivacyPolicy" :href="privacyInfo.privacy_policy_url" target="_blank">
              {{ $t('global.privacy') }}
            </a>
          </i18n>
          <i18n v-else path="scenes.ekyc.tips.no-agreement-policy" tag="p">
            <a place="providerPrivacyPolicy" :href="privacyInfo.providerPolicyUrl" target="_blank">
              {{ privacyInfo.providerPolicy }}
            </a>
          </i18n>
        </div>
        <button
          class="kycVerifyModal-continueBtn call-to-action dark d-block btn-primary-hv"
          data-testid="eKYC-continue-button"
          @click="kycSubmit()"
        >
          {{ $t('global.continue') }}
        </button>
      </div>

      <b-embed
        v-else
        type="iframe"
        :src="modalSrc"
        allow="camera;fullscreen;accelerometer;gyroscope;magnetometer"
        allowfullscreen
      />
    </div>
  </b-modal>
</template>

<script>
  export default {
    name: 'kyc-verify-modal.vue',
    data() {
      return {
        showTips: true,
        modalSrc: '',
        transactionId: null,
        privacyInfo: {
          kyc_type: 'no_agreement',
          providerPolicy: this.$t('scenes.ekyc.tips.provider-policy'),
          providerPolicyUrl: this.$t('url.identity.authentication.provider-privacy-policy'),
        },
      }
    },
    computed: {
      kycVerifyModalData: {
        get() {
          const { privacyInfo } = this.$store.getters.get_kyc_verify_modal_data

          if (privacyInfo) {
            this.privacyInfo = {
              ...privacyInfo,
              ...(privacyInfo.kyc_type === 'agreement_checkbox' ? { checkbox: false } : null),
              providerPolicy: this.$t('scenes.ekyc.tips.provider-policy'),
              providerPolicyUrl: this.$t('url.identity.authentication.provider-privacy-policy'),
            }
          }

          return this.$store.getters.get_kyc_verify_modal_data
        },
      },
    },
    methods: {
      async requestJumio() {
        const requestData = {
          package_id: this.kycVerifyModalData.package_id || undefined,
        }
        try {
          const transaction = await this.$axios.post('/v2/users/me/identities', requestData)
          const data = transaction.data
          if (data) {
            if (data.web_url) {
              this.modalSrc = data.web_url.replace('locale=en', `locale=${this.$i18n.locale}`)
            }
            if (data.transaction_id) {
              this.transactionId = data.transaction_id
            }
          }
        } catch (error) {
          this.$bvToast.toast(error.response.data.message, {
            title: this.$t('global.error.title'),
            variant: 'danger',
            solid: true,
          })
          this.$refs.kycVerifyModal.hide()
        }
      },
      async updateStatus() {
        try {
          await this.$axios.put('/v2/users/me/identities/' + this.transactionId, {}).then((response) => {
            this.kycVerifyModalData.isOneTime
              ? this.$store.dispatch('fetch_package_identity', this.kycVerifyModalData.package_id)
              : this.$store.dispatch('fetch_user_identities_default')
            this.kycVerifyModalHidden()
            this.$bvToast.toast(response.data.message, {
              title: this.$t('messages.success.title'),
              variant: 'success',
              solid: true,
            })
          })
        } catch (error) {
          this.$bvToast.toast(error.response.data.message, {
            title: this.$t('global.error.title'),
            variant: 'danger',
            solid: true,
          })
        }
      },
      receiveMessage(event) {
        const eventDataCheck = event?.data

        if (eventDataCheck) {
          try {
            const eventData = typeof eventDataCheck === 'string' ? JSON.parse(eventDataCheck) : eventDataCheck
            if (eventData?.payload?.value == 'success' && this.transactionId) {
              this.updateStatus()
            }
          } catch (error) {}
        }
      },
      kycSubmit() {
        if (this.privacyInfo && this.privacyInfo.kyc_type === 'agreement_checkbox' && !this.privacyInfo.checkbox) {
          this.$bvToast.toast(this.$t('messages.error.kyc-tips-agreement-not-checked'), {
            title: this.$t('global.error.title'),
            variant: 'danger',
            solid: true,
          })
        } else {
          this.showTips = false
        }
      },
      kycVerifyModalHidden() {
        this.showTips = true
        this.modalSrc = ''
        this.transactionId = null
        const privacyInfo = {
          kyc_type: 'no_agreement',
          providerPolicy: this.$t('scenes.ekyc.tips.provider-policy'),
          providerPolicyUrl: this.$t('url.identity.authentication.provider-privacy-policy'),
        }

        this.$store.commit('set_kyc_verify_modal_data', {
          isShow: false,
          privacyInfo,
          package_id: null,
          isOneTime: false,
        })
      },
    },
    watch: {
      showTips(val) {
        if (val) {
          this.modalSrc = ''
          this.transactionId = null
        } else {
          this.requestJumio()
        }
      },
    },
    beforeDestroy() {
      window.removeEventListener('message', this.receiveMessage)
    },
    mounted() {
      window.addEventListener('message', this.receiveMessage, false)
    },
  }
</script>

<style lang="scss">
  .kycVerifyModal-dialog .modal-header .close {
    [dir='rtl'] & {
      margin: -1rem auto -1rem -1rem;
    }
  }
  .kycVerifyModal-dialog {
    &.modal-md {
      max-width: 540px;
    }

    .modal-content {
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
    }
  }
  .kycVerifyModal-header {
    .package-kycVerifyModal-title {
      color: #4a4a4a;
      font-size: 21px;
      font-weight: 600;
      letter-spacing: -0.3px;
      line-height: 25px;
      margin: 24px 0 2px 4px;
    }
    button.close {
      padding: 0;
      opacity: 1;
      img {
        margin-top: 9px;
        margin-right: 9px;

        [dir='rtl'] & {
          margin-right: unset;
          margin-left: 9px;
        }
      }
    }
  }
  .kycVerifyModal-body {
    padding: 20px 20px 40px 20px;

    .kycVerifyModal-content {
      .tips-description {
        color: #8a8a8a;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 21px;

        ul > li:not(:last-child) {
          margin-bottom: 20px;
        }
      }
      &-privacy {
        color: #8a8a8a;
        font-size: 13px;
        letter-spacing: 0;
        line-height: 18px;
        margin-bottom: 20px;

        p {
          margin-bottom: 0;
        }

        a {
          font-weight: 600;
        }
      }
      .kycVerifyModal-continueBtn {
        color: #ffffff;
        font-size: 11px;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 11px;
        text-align: center;
        padding: 16px 20px 15px;
      }
    }

    .embed-responsive {
      min-height: 70vh;
    }
  }
</style>
